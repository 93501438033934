
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFourThreeThreeBApi } from '../../resources/hooks/api/fourThreeThreeBApiHook';
import Select from 'react-select';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { MONTHS, STATES } from '../../resources/constants';
import { calculateMaxHeightContent, lastYears } from '../../resources/functions';
import Form from 'react-bootstrap/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { CreateFourThreeThreeFormI, PersonalBalanceFormI, PersonalIncomeFormI } from '../../resources/form-props';
import { PersonalBalanceValidate, PersonalIncomeValidate } from '../../resources/form-validator'
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import moment from 'moment'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';

import { getBranch } from '../../store/user/selectors'

interface Props {

}

const PersonalIncomeStatement: React.FC<Props> = () => {

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const initData = {
        section_one_primary_income: [],
        section_one_other_income: [],
        section_two_fixed_expense: [],
        section_two_variable_expense: [],
        section_three_investments: [],
        section_four_budgets: [],
    };
    const branchData = useSelector(getBranch)
    const pagesRefs = useRef<(HTMLDivElement | null)[]>([]);

    const { getPersonalIncomeStatment, createPersonalIncomeStatment } = useFourThreeThreeBApi();
    const [currentMonthIndex, setCurrentMonthIndex] = useState<any>(new Date().getMonth());
    const currentMonthName = months[currentMonthIndex];
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useState<any>({
        month: { value: new Date().getMonth() + 1, label: currentMonthName },
        year: { value: new Date().getFullYear(), label: '' + new Date().getFullYear() }
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [totalSectionOneMonthlyIncome, setTotalSectionOneMonthlyIncome] = useState<number>(0);
    const [totalSectionOneAnnualIncome, setTotalSectionOneAnnualIncome] = useState<number>(0);

    const [totalSectionTwoFixedMonthlyIncome, setSectionTwoFixedMonthlyIncome] = useState<number>(0);
    const [totalSectionTwoFixedAnnualIncome, setSectionTwoFixedAnnualIncome] = useState<number>(0);

    const [totalSectionTwoVariableMonthlyIncome, setSectionTwoVariableMonthlyIncome] = useState<number>(0);
    const [totalSectionTwoVariableAnnualIncome, setSectionTwoVariableAnnualIncome] = useState<number>(0);

    const [totalSectionThreeMonthlyIncome, setSectionThreeMonthlyIncome] = useState<number>(0);
    const [totalSectionThreeAnnualIncome, setSectionThreeAnnualIncome] = useState<number>(0);

    const [totalSectionFourMonthlyIncome, setSectionFourMonthlyIncome] = useState<number>(0);
    const [totalSectionFourAnnualIncome, setSectionFourAnnualIncome] = useState<number>(0);

    const { control, register, reset, setValue, getValues, watch, handleSubmit, formState: { errors } } = useForm<PersonalIncomeFormI>({
        defaultValues: initData,
        // resolver: yupResolver(PersonalIncomeValidate)
    });

    useEffect(() => {
        // reset(initData);
        // initialiseForm();
        fetchMonthlyIncome();
    }, [])

    const initialiseForm = () => {

        addMoreSectionOneOtherIncome([
            { title: 'Child Support, Alimony', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Social Security Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Disability Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Pensions', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Investments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Real Estate Investments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Business Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Other Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: true },
        ]);
        addMoreSectionTwoFixedExpense([
            { title: 'Payroll Taxes', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Other income deductions', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Rent/Mortgage', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Loans (Home Equity, 2nd Mortgage)', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Property Taxes', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Water', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Garbage', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Gas and Electric Bills', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Auto Insurance', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Auto repairs', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Groceries (Necessary Items Needed Monthly)', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Necessary Clothing', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Landline Phone', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Home / Renters Insurance', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Healthcare Insurance Premium', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Dental Insurance Premium', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Life Insurance Premium', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Student Loan Payment/Monthly', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Child/dependent Support Expenses', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Alimony/ Spousal Support', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Home Owners Association Dues', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Other', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: true },
        ]);
        addMoreSectionTwoVariableExpense([
            { title: 'Credit Card Payments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Auto Loan Payments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Gas', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Cable or TV', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Mobile Phone', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Home Improvement/Repairs', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Home supplies', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Dry cleaning', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Laundry Other', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Investment Real Estate Expenses', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Childcare (daycare & babysitters)', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Child & Baby Expenses Other', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Entertainment', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Dining Out', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Vacations', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Pet Costs', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Gifts (For yourself or someone else)', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Internet', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Technology', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Other', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: true },
        ]);
        addMoreSectionThreeInvestments([
            { title: 'IRA Deposits (annual)', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: false },
            { title: 'Employee Stock Plans', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Brokerage Deposits', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Other investments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: true },
        ]);
        addMoreSectionFourBudgets([
            { title: 'Total Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true },
            { title: 'Fixed Expenses', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true },
            { title: 'Net Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true },
            { title: 'Investments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true },
            { title: 'Net Income minus investments', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true },
            { title: 'Variable Expenses', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true },
        ]);
        addMoreSectionOnePrimaryIncome([
            { title: 'Your Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
            { title: 'Your Spouse\'s Income', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: true, monthly_amount_read_only: false, annual_amount_read_only: true },
        ]);

        setTotalSectionOneMonthlyIncome(0);
        setTotalSectionOneAnnualIncome(0);

        setSectionTwoFixedMonthlyIncome(0);
        setSectionTwoFixedAnnualIncome(0);

        setSectionTwoVariableMonthlyIncome(0);
        setSectionTwoVariableAnnualIncome(0);

        setSectionThreeMonthlyIncome(0);
        setSectionThreeAnnualIncome(0);

        setSectionFourMonthlyIncome(0);
        setSectionFourAnnualIncome(0);

        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 0);
    }

    const calculateSectionOneTotalIncome = () => {
        let monthlyTotal = 0, annualTotal = 0;

        getValues().section_one_primary_income.forEach((field: any) => {
            monthlyTotal += +field.monthly_amount || 0;
        });
        getValues().section_one_other_income.forEach((field: any) => {
            monthlyTotal += +field.monthly_amount || 0;
        });

        getValues().section_one_primary_income.forEach((field: any) => {
            annualTotal += +field.annual_amount || 0;
        })
        getValues().section_one_other_income.forEach((field: any) => {
            annualTotal += +field.annual_amount || 0;
        })

        setTotalSectionOneMonthlyIncome(monthlyTotal);
        setValue('section_four_budgets.0.monthly_amount', +parseFloat('' + monthlyTotal).toFixed(2));
        setValue('section_four_budgets.2.monthly_amount', +parseFloat('' + (monthlyTotal - getValues('section_four_budgets.1.monthly_amount'))).toFixed(2));

        setTotalSectionOneAnnualIncome(annualTotal);
        setValue('section_four_budgets.0.annual_amount', +parseFloat('' + annualTotal).toFixed(2));
        setValue('section_four_budgets.2.annual_amount', +parseFloat('' + (annualTotal - getValues('section_four_budgets.1.annual_amount'))).toFixed(2));

        setValue('section_four_budgets.4.monthly_amount', +parseFloat('' + (monthlyTotal - getValues('section_four_budgets.1.monthly_amount') - getValues('section_four_budgets.3.monthly_amount'))).toFixed(2));
        setValue('section_four_budgets.4.annual_amount', +parseFloat('' + (annualTotal - getValues('section_four_budgets.1.annual_amount') - getValues('section_four_budgets.3.annual_amount'))).toFixed(2));

        calculateSectionFourIncome();
    }
    const calculateSectionTwoFixedIncome = () => {
        let monthlyTotal = 0, annualTotal = 0;

        getValues().section_two_fixed_expense.forEach((field: any) => {
            monthlyTotal += +field.monthly_amount || 0;
        });
        getValues().section_two_fixed_expense.forEach((field: any) => {
            annualTotal += +field.annual_amount || 0;
        })

        setSectionTwoFixedMonthlyIncome(monthlyTotal);
        setValue('section_four_budgets.1.monthly_amount', +parseFloat('' + monthlyTotal).toFixed(2));
        setValue('section_four_budgets.2.monthly_amount', +parseFloat('' + (getValues('section_four_budgets.0.monthly_amount') - monthlyTotal)).toFixed(2));

        setSectionTwoFixedAnnualIncome(annualTotal);
        setValue('section_four_budgets.1.annual_amount', +parseFloat('' + annualTotal).toFixed(2));
        setValue('section_four_budgets.2.annual_amount', +parseFloat('' + (getValues('section_four_budgets.0.annual_amount') - annualTotal)).toFixed(2));

        setValue('section_four_budgets.4.monthly_amount', +parseFloat('' + (getValues('section_four_budgets.0.monthly_amount') - monthlyTotal - getValues('section_four_budgets.3.monthly_amount'))).toFixed(2));
        setValue('section_four_budgets.4.annual_amount', +parseFloat('' + (getValues('section_four_budgets.0.annual_amount') - annualTotal - getValues('section_four_budgets.3.annual_amount'))).toFixed(2));

        calculateSectionFourIncome();
    };
    const calculateSectionTwoVariableIncome = () => {
        let monthlyTotal = 0, annualTotal = 0;
        getValues().section_two_variable_expense.forEach((field: any) => {
            monthlyTotal += +field.monthly_amount || 0;
        });
        getValues().section_two_variable_expense.forEach((field: any) => {
            annualTotal += +field.annual_amount || 0;
        })

        setSectionTwoVariableMonthlyIncome(monthlyTotal);
        setSectionTwoVariableAnnualIncome(annualTotal);

        setValue('section_four_budgets.5.monthly_amount', +parseFloat('' + monthlyTotal).toFixed(2));
        setValue('section_four_budgets.5.annual_amount', +parseFloat('' + annualTotal).toFixed(2));

        calculateSectionFourIncome()
    };
    const calculateSectionThreeIncome = () => {
        let monthlyTotal = 0, annualTotal = 0;
        getValues().section_three_investments.forEach((field: any) => {
            monthlyTotal += +field.monthly_amount || 0;
        });
        getValues().section_three_investments.forEach((field: any) => {
            annualTotal += +field.annual_amount || 0;
        });

        setSectionThreeMonthlyIncome(monthlyTotal);
        setValue('section_four_budgets.3.monthly_amount', +parseFloat('' + monthlyTotal).toFixed(2));
        setValue('section_four_budgets.4.monthly_amount', +parseFloat('' + (getValues('section_four_budgets.2.monthly_amount') - monthlyTotal)).toFixed(2));

        setSectionThreeAnnualIncome(annualTotal);
        setValue('section_four_budgets.3.annual_amount', +parseFloat('' + annualTotal).toFixed(2));
        setValue('section_four_budgets.4.annual_amount', +parseFloat('' + (getValues('section_four_budgets.2.annual_amount') - annualTotal)).toFixed(2));

        calculateSectionFourIncome()
    };
    const calculateSectionFourIncome = () => {
        let monthlyTotal = 0, annualTotal = 0;
        getValues().section_four_budgets.forEach((field) => {
            monthlyTotal += field.monthly_amount || 0;
        });
        getValues().section_four_budgets.forEach((field) => {
            annualTotal += field.annual_amount || 0;
        });

        setSectionFourMonthlyIncome(monthlyTotal);
        setSectionFourAnnualIncome(annualTotal);
    };

    const { fields: sectionOnePrimaryIncomeFields, append: addMoreSectionOnePrimaryIncome, remove: deleteSectionOnePrimaryIncome } = useFieldArray({
        control,
        name: 'section_one_primary_income',
    });
    const { fields: sectionOneOtherIncomeFields, append: addMoreSectionOneOtherIncome, remove: deleteSectionOneOtherIncome } = useFieldArray({
        control,
        name: 'section_one_other_income',
    });
    const { fields: sectionTwoFixedExpenseFields, append: addMoreSectionTwoFixedExpense, remove: deleteSectionTwoFixedExpense } = useFieldArray({
        control,
        name: 'section_two_fixed_expense',
    });
    const { fields: sectionTwoVariableExpenseFields, append: addMoreSectionTwoVariableExpense, remove: deleteSectionTwoVariableExpense } = useFieldArray({
        control,
        name: 'section_two_variable_expense',
    });
    const { fields: sectionThreeInvestmentsFields, append: addMoreSectionThreeInvestments, remove: deleteSectionThreeInvestments } = useFieldArray({
        control,
        name: 'section_three_investments',
    });
    const { fields: sectionFourBudgetsFields, append: addMoreSectionFourBudgets, remove: deleteSectionFourBudgets } = useFieldArray({
        control,
        name: 'section_four_budgets',
    });


    const _handleChangeSearchParam = (e: any, type: string) => {
        if (type === 'month') {
            setSearchParams({
                ...searchParams,
                'month': e
            })
        } else if (type === 'year') {
            setSearchParams({
                ...searchParams,
                'year': e
            })
        }
    }

    const fetchMonthlyIncome = () => {
        setIsLoading(true);
        const data = {
            // month: searchParams['month']['value'],
            year: searchParams['year']['value'],
            branch_id: +branchData['id']
        }
        getPersonalIncomeStatment(data, (message: string, resp: any) => {
            const data = resp.data.data;
            reset();

            if (data) {
                setIsEdit(true)
                data['section_one_primary_income'].map((item: any) => {
                    addMoreSectionOnePrimaryIncome({ ...item, title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true })
                })
                data['section_one_other_income'].map((item: any) => {
                    addMoreSectionOneOtherIncome({ ...item, title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true })
                })
                data['section_two_fixed_expense'].map((item: any) => {
                    addMoreSectionTwoFixedExpense({ ...item, title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true })
                })
                data['section_two_variable_expense'].map((item: any) => {
                    addMoreSectionTwoVariableExpense({ ...item, title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true })
                })
                data['section_three_investments'].map((item: any) => {
                    addMoreSectionThreeInvestments({ ...item, title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true })
                })
                data['section_four_budgets'].map((item: any) => {
                    addMoreSectionFourBudgets({ ...item, title_read_only: true, monthly_amount_read_only: true, annual_amount_read_only: true })
                })

                calculateSectionOneTotalIncome();
                calculateSectionTwoFixedIncome();
                calculateSectionTwoVariableIncome();
                calculateSectionThreeIncome();
                calculateSectionFourIncome();

                setTimeout(() => {
                    window.scrollTo(0, 0)
                }, 0);
            } else {
                setIsEdit(false);
                reset(initData);
                initialiseForm();
            }

            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
        })
    }

    const saveMonthlyIncome = (data: any) => {
        setIsLoading(true);
        const payload = {
            queryString: {
                year: searchParams['year']['value'],
                branch_id: +branchData['id']
            },
            data
        }

        createPersonalIncomeStatment(payload, (message: string, resp: any) => {
            captureContentAndGeneratePDF();
            // generatePdf();
            setIsLoading(false);
            toast.success(message);
            // reset(initData);
            // initialiseForm();
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const submitForm = () => {
        handleSubmit(saveMonthlyIncome)();
    };

    const generatePdf = async () => {
        const element = document.querySelector('.personal-balance-sheet.main-table') as HTMLElement;

        if (!element) {
            alert('Table not found!');
            return;
        }

        // Use html2pdf.js to generate the PDF
        const opt = {
            margin: 0.5,
            filename: 'personal_balance_sheet.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf()
            .from(element)
            .set(opt)
            .save();
    };


    const captureContentAndGeneratePDF = async () => {
        const pdf = new jsPDF('p', 'pt', 'a4');

        for (let i = 0; i < pagesRefs.current.length; i++) {
            const pageRef = pagesRefs.current[i];

            if (pageRef === null) {
                continue; // Skip if ref is null
            }

            // Capture the content of each page
            const canvas = await html2canvas(pageRef, { scale: 3 });

            // Add a new page if not the first page
            if (i > 0) {
                pdf.addPage();
            }

            // Add the captured content as an image to the PDF
            pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
        }

        // Save or download the PDF
        pdf.save(`Personal/Family Income Statement(${searchParams['year']['value']}).pdf`);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            event.preventDefault();
        }
    };

    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Personal/Family Income Statement</h1>
                        </div>
                    </div>
                </div>
                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="filter-wrap">
                                {/* <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'month')}
                                        options={MONTHS}
                                        defaultValue={searchParams['month']}
                                        className={'zindex-10'}
                                    />
                                </div> */}
                                <div className="filter-box">
                                    <Select
                                        onChange={(e: any) => _handleChangeSearchParam(e, 'year')}
                                        options={lastYears(5)}
                                        defaultValue={searchParams['year']}
                                        className={'zindex-10'}
                                    />
                                </div>

                                <div className="filter-box">
                                    <button type="button" onClick={fetchMonthlyIncome} className="generate-btn">Generate Form</button>
                                </div>
                                <div className="filter-btn">
                                    <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                        <div className="loader"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="executive-dashboard personal-balance-sheet">
                    <div style={{ padding: 0, margin: 0, textAlign: 'center' }}>
                        <Form onKeyDown={handleKeyDown} /*onSubmit={handleSubmit(saveMonthlyIncome)}*/>
                            <div>
                                <>{console.log('error: ', errors)}</>
                                <table className="personal-balance-sheet main-table" ref={ref => pagesRefs.current[0] = ref}>
                                    <tr>
                                        <td colSpan={12}>
                                            {/* <h2 className="header">ANNUAL INCOME</h2> */}
                                            <table className="section-table">
                                                <tr className="header">
                                                    <td colSpan={6}>Section 1: Income Sources</td>
                                                    <td colSpan={1}>Monthly Budget (in $)</td>
                                                    <td colSpan={1}>Annual Amount (in $)</td>
                                                    <td colSpan={4}>Notes</td>
                                                </tr>
                                                <tr>
                                                    <td className='header-title' colSpan={12}>Primary Income</td>
                                                </tr>
                                                {sectionOnePrimaryIncomeFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={6}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_one_primary_income.${index}.title`)}
                                                                placeholder="Title"
                                                                readOnly={item.title_read_only}
                                                                className={item.title_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_one_primary_income.${index}.monthly_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.monthly_amount_read_only}
                                                                onChange={(e) => {
                                                                    const annualAmount = +e.target.value * 12;
                                                                    setValue(`section_one_primary_income.${index}.monthly_amount`, +e.target.value);
                                                                    setValue(`section_one_primary_income.${index}.annual_amount`, +parseFloat('' + annualAmount).toFixed(2));
                                                                    calculateSectionOneTotalIncome();
                                                                }}
                                                                className={item.monthly_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_one_primary_income.${index}.annual_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.annual_amount_read_only}
                                                                className={item.annual_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_one_primary_income.${index}.notes`)}
                                                                placeholder="Note"
                                                            />
                                                        </td>
                                                        {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td className='header-title' colSpan={12}>Do you have other income sources?</td>
                                                </tr>
                                                {sectionOneOtherIncomeFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={6}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_one_other_income.${index}.title`)}
                                                                placeholder="Title"
                                                                readOnly={item.title_read_only}
                                                                className={item.title_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_one_other_income.${index}.monthly_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.monthly_amount_read_only}
                                                                onChange={(e) => {
                                                                    const annualAmount = +e.target.value * 12;
                                                                    setValue(`section_one_other_income.${index}.monthly_amount`, +e.target.value);
                                                                    setValue(`section_one_other_income.${index}.annual_amount`, +parseFloat('' + annualAmount).toFixed(2));
                                                                    calculateSectionOneTotalIncome();
                                                                }}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_one_other_income.${index}.annual_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.annual_amount_read_only}
                                                                className={item.annual_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_one_other_income.${index}.notes`)}
                                                                placeholder="Note"
                                                            />
                                                        </td>
                                                        {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <button type="button" className='button' onClick={() => addMoreSectionOneOtherIncome({ title: '', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: false })}>Add Row</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                <tr className="total-row">
                                                    <td colSpan={6}>Total Household Income</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionOneMonthlyIncome).toFixed(2)}</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionOneAnnualIncome).toFixed(2)}</td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                                <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                                <tr className="header">
                                                    <td colSpan={6}>Section 2: Expenses- Fixed/Variable</td>
                                                    <td colSpan={1}>Monthly Budget (in $)</td>
                                                    <td colSpan={1}>Annual Amount (in $)</td>
                                                    <td colSpan={4}>Notes</td>
                                                </tr>
                                                <tr>
                                                    <td className='header-title' colSpan={12}>Expenses: Fixed</td>
                                                </tr>
                                                {sectionTwoFixedExpenseFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={6}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_two_fixed_expense.${index}.title`)}
                                                                placeholder="Title"
                                                                readOnly={item.title_read_only}
                                                                className={item.title_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_two_fixed_expense.${index}.monthly_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.monthly_amount_read_only}
                                                                onChange={(e) => {
                                                                    const annualAmount = +e.target.value * 12;
                                                                    setValue(`section_two_fixed_expense.${index}.monthly_amount`, +e.target.value);
                                                                    setValue(`section_two_fixed_expense.${index}.annual_amount`, +parseFloat('' + annualAmount).toFixed(2));
                                                                    calculateSectionTwoFixedIncome();
                                                                }}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_two_fixed_expense.${index}.annual_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.annual_amount_read_only}
                                                            />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_two_fixed_expense.${index}.notes`)}
                                                                placeholder="Note"
                                                            />
                                                        </td>
                                                        {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <button type="button" className='button' onClick={() => addMoreSectionTwoFixedExpense({ title: '', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: false })}>Add Row</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                <tr className="total-row">
                                                    <td colSpan={6}>Total Fixed Expenses</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionTwoFixedMonthlyIncome).toFixed(2)}</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionTwoFixedAnnualIncome).toFixed(2)}</td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                                <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                                <tr>
                                                    <td className='header-title' colSpan={12}>Expenses: Variable </td>
                                                </tr>
                                                {sectionTwoVariableExpenseFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={6}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_two_variable_expense.${index}.title`)}
                                                                placeholder="Title"
                                                                readOnly={item.title_read_only}
                                                                className={item.title_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_two_variable_expense.${index}.monthly_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.monthly_amount_read_only}
                                                                onChange={(e) => {
                                                                    const annualAmount = +e.target.value * 12;
                                                                    setValue(`section_two_variable_expense.${index}.monthly_amount`, +e.target.value);
                                                                    setValue(`section_two_variable_expense.${index}.annual_amount`, +parseFloat('' + annualAmount).toFixed(2));
                                                                    calculateSectionTwoVariableIncome();
                                                                }}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_two_variable_expense.${index}.annual_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.annual_amount_read_only}
                                                            />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_two_variable_expense.${index}.notes`)}
                                                                placeholder="Note"
                                                            />
                                                        </td>
                                                        {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <button type="button" className='button' onClick={() => addMoreSectionTwoVariableExpense({ title: '', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: false })}>Add Row</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                <tr className="total-row">
                                                    <td colSpan={6}>Total Variable Expenses:</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionTwoVariableMonthlyIncome).toFixed(2)}</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionTwoVariableAnnualIncome).toFixed(2)}</td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                                <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                                <tr className="header">
                                                    <td colSpan={6}>Section 3: Your Investments</td>
                                                    <td colSpan={1}>Monthly Budget (in $)</td>
                                                    <td colSpan={1}>Annual Amount (in $)</td>
                                                    <td colSpan={4}>Notes</td>
                                                </tr>
                                                <tr>
                                                    <td className='header-title' colSpan={12}>Investment Spending: *Fixed monthly or annual investments listed here, not discretionary investing.</td>
                                                </tr>
                                                {sectionThreeInvestmentsFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={6}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_three_investments.${index}.title`)}
                                                                placeholder="Title"
                                                                readOnly={item.title_read_only}
                                                                className={item.title_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_three_investments.${index}.monthly_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.monthly_amount_read_only}
                                                                onChange={(e) => {
                                                                    const annualAmount = +e.target.value * 12;
                                                                    setValue(`section_three_investments.${index}.monthly_amount`, +e.target.value);
                                                                    setValue(`section_three_investments.${index}.annual_amount`, +parseFloat('' + annualAmount).toFixed(2));
                                                                    calculateSectionThreeIncome();
                                                                }}
                                                                className={item.monthly_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_three_investments.${index}.annual_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.annual_amount_read_only}
                                                                onChange={(e) => {
                                                                    const annualAmount = +e.target.value / 12;
                                                                    setValue(`section_three_investments.${index}.annual_amount`, +e.target.value);
                                                                    setValue(`section_three_investments.${index}.monthly_amount`, +parseFloat('' + annualAmount).toFixed(2));
                                                                    calculateSectionThreeIncome();
                                                                }}
                                                                className={item.annual_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_three_investments.${index}.notes`)}
                                                                placeholder="Note"
                                                            />
                                                        </td>
                                                        {/* <button type="button" onClick={() => deleteAnnualIncome(index)}>Remove</button> */}
                                                    </tr>
                                                ))}
                                                {
                                                    !isEdit && (
                                                        <tr>
                                                            <td colSpan={12}>
                                                                <button type="button" className='button' onClick={() => addMoreSectionThreeInvestments({ title: '', monthly_amount: 0, annual_amount: 0, notes: '', title_read_only: false, monthly_amount_read_only: false, annual_amount_read_only: true })}>Add Row</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                <tr className="total-row">
                                                    <td colSpan={6}>Total Investment Costs:</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionThreeMonthlyIncome).toFixed(2)}</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionThreeAnnualIncome).toFixed(2)}</td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                                <tr><td colSpan={12} style={{ height: '20px' }}></td></tr>
                                                <tr className="header">
                                                    <td colSpan={6}>Section 4: Your Budget Results</td>
                                                    <td colSpan={1}>Monthly Budget (in $)</td>
                                                    <td colSpan={1}>Annual Amount (in $)</td>
                                                    <td colSpan={4}>Notes</td>
                                                </tr>
                                                {sectionFourBudgetsFields.map((item: any, index: number) => (
                                                    <tr key={item.id}>
                                                        <td colSpan={6}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_four_budgets.${index}.title`)}
                                                                placeholder="Title"
                                                                readOnly={item.title_read_only}
                                                                className={item.title_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_four_budgets.${index}.monthly_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.monthly_amount_read_only}
                                                                className={item.monthly_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={1}>
                                                            <input
                                                                type='number'
                                                                {...register(`section_four_budgets.${index}.annual_amount`)}
                                                                placeholder="Amount"
                                                                readOnly={item.annual_amount_read_only}
                                                                className={item.annual_amount_read_only ? 'input-disabled-look' : ''}
                                                            />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <input
                                                                type='text'
                                                                {...register(`section_four_budgets.${index}.notes`)}
                                                                placeholder="Note"
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className="total-row">
                                                    <td colSpan={6}>Disposable Income/Savings</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionFourMonthlyIncome).toFixed(2)}</td>
                                                    <td colSpan={1}>${parseFloat(''+totalSectionFourAnnualIncome).toFixed(2)}</td>
                                                    <td colSpan={4}></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <button type="button" onClick={submitForm} className="btn btn-secondary mt-4">
                                {showLoader && (<Spinner animation="border" size="sm" />) || 'Save and Generate PDF'}
                            </button>


                        </Form>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default PersonalIncomeStatement;